<template>
  <!--资料审核页-->
  <div class="table-responsive mt-5">
    <div class="d-flex align-items-center">
      <!-- 左边 -->
      <el-button-group>
        <el-button type="success" size="mini" @click="order_status = 2"
          >待审核</el-button
        >
        <el-button type="success" size="mini" @click="order_status = 3"
          >已审核</el-button
        >
        <el-button type="success" size="mini" @click="order_status = 6"
          >资料不全</el-button
        >
      </el-button-group>
      <!-- 右边 -->
      <div class="ml-auto">
        <span>选择日期：</span>
        <date-picker @select-change="datepickerselect"></date-picker>
        <el-input
          v-model="formdata.name"
          placheholder="要搜索的供应商名称"
          size="mini"
          style="width: 150px"
          class="mr-2 ml-3"
        ></el-input>
        <el-button type="info" size="mini">搜索</el-button>
      </div>
    </div>

    <el-table
      border
      class="mt-3"
      :data="tableData.data"
      style="width: 100%"
      :show-summary="true"
      :summary-method="summaryMethod"
      height="650"
    >
      <el-table-column label="状态" width="180" align="center">
        <template #default="scope">
          <el-button
            size="small"
            type="success"
            round
            @click="getsupplierfkinfo(scope.row)"
            v-if="scope.row.order_status === 2"
            >待审核</el-button
          >
          <el-button
            size="small"
            type="warning"
            round
            @click="getsupplierfkinfo(scope.row)"
            v-if="scope.row.order_status === 3"
            >审核通过</el-button
          >
          <el-button
            size="small"
            type="danger"
            round
            @click="getsupplierfkinfo(scope.row)"
            v-if="scope.row.order_status === 6"
            >资料不齐</el-button
          >
          <el-button size="small" plain @click="pdfview(scope.row.id)"
            >附件</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template #default="scope">
          <el-button
            size="small"
            plain
            v-if="scope.row.order_status === 3"
            @click="revocation_pass(scope.row.id)"
            >撤销</el-button
          >
          <el-button
            size="small"
            plain
            v-if="scope.row.order_status === 6"
            @click="revocation_nopass(scope.row.id)"
            >撤销</el-button
          >
          <el-button-group v-if="scope.row.order_status === 2">
            <el-button size="small" plain @click="pass_status(scope.row.id)"
              >通过</el-button
            >
            <el-button
              size="small"
              plain
              type="danger"
              @click="no_pass_status(scope.row.id)"
              >不通过</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column
        prop="sq_date"
        label="日期"
        sortable
        width="100"
        align="center"
      />
      <el-table-column
        prop="id_no"
        label="凭证编号"
        width="140"
        align="center"
      />
      <el-table-column
        prop="SupplierInfo.supplier_name"
        sortable
        label="供应商名称"
        width="240"
        align="center"
      />
      <el-table-column
        prop="sq_amount"
        label="付款金额"
        width="180"
        align="center"
        :formatter="toThousands"
      />
      <el-table-column
        prop="category_id_t"
        sortable
        label="成本类别"
        width="180"
        align="center"
      />
      <el-table-column
        prop="fk_text"
        label="付款说明"
        width="180"
        align="center"
      />

      <el-table-column
        prop="Managerproposer.usealias"
        label="申请人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managerapprover.usealias"
        label="批准人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managercfo.usealias"
        label="审核人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managerregistrant.usealias"
        label="付款人"
        width="180"
        align="center"
      />
    </el-table>
    <!-- 显示供应商合同/付款/发票情况 -->
    <el-drawer v-model="drawer" title="合同/付款/发票一览表" size="50%">
      <get-supplier-fkinfo
        :supplier_info_id="supplier_info_id"
        :project_id="project_id"
      ></get-supplier-fkinfo>
    </el-drawer>
    <!-- 查看PDF文件List -->
    <el-dialog v-model="pdfurlVisible" width="100%" fullscreen>
      <pdf-view fileclass="2" :prdclassid="prdclassid"></pdf-view>
    </el-dialog>
  </div>
  <div style="height: 60px"></div>
  <!-- 占位底部60PX -->
  <el-footer
    class="border-top d-flex align-items-center px-0 position-fixed bg-white"
    style="bottom: 0; left: 150px; right: 0; z-index: 100"
  >
    <el-config-provider :locale="locale">
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[5, 10, 20, 100, 300]"
        layout="sizes, prev, pager, next,total,jumper"
        :total="lotalrows"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        prev-text="上一页"
        next-text="下一页"
        background
      >
      </el-pagination
    ></el-config-provider>
  </el-footer>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
import zhCnelementpage from "element-plus/lib/locale/lang/zh-cn";
export default {
  data() {
    return {
      //分页数据
      pageSize: 10, //每页数据 默认10条
      currentPage: 1, //当前页码  默认第1页
      lotalrows: 0, //总行数
      locale: zhCnelementpage, //中文分页
      //
      prdclassid: "", //查看附件记录的ID
      pdfurlVisible: false, //查看附件列表开关
      //
      project_id: window.sessionStorage.getItem("project_ids"),
      user_id: JSON.parse(window.sessionStorage.getItem("user")).id,
      order_status: 2,
      //DatePicker 日期选择器
      startDate:'1900-1-1',
      endDate:'2099-12-31',
      //
      formdata: {
        name: "",
      },
      //
      tableData: {
        data: [],
      },
      //供应商ID 显示供应商合同/付款/发票情况
      supplier_info_id: "",
      drawer: false,
    };
  },
  watch: {
    order_status() {
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
  },
  created() {
    //参数2 表示：待审核状态
    this.loadtabledata(2, this.pageSize, this.currentPage);
  },
  methods: {
  //DatePicker 日期选择器事件
    datepickerselect(val){
      this.startDate = val[0]
      this.endDate = val[1]
      console.log('日期选择器事件',this.startDate,this.endDate)
    },
    //<!-- 显示供应商合同/付款/发票情况 -->
    getsupplierfkinfo(row) {
      console.log(row.supplier_info_id);
      this.supplier_info_id = row.supplier_info_id;
      this.drawer = true;
    },
    //选择页码选项
    handleCurrentChange(val) {
      console.log("选择页码选项", val);
      this.currentPage = val;
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
    //选择每页条数
    handleSizeChange(val) {
      console.log("选择每页条数", val);
      this.pageSize = val;
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
    ////单击不通过按钮事件
    no_pass_status(id_pors) {
      //参数一：记录ID  参数二:6 表示审批通过，资料不全
      //参数三: 2 重新加载数据   表示：待审核状态
      this.updatetabledata(id_pors, 6, 2);
    },
    //单击通过按钮事件
    pass_status(id_pors) {
      //参数一：记录ID  参数二:3 表示审批通过
      //参数三: 2 重新加载数据   表示：待审核状态
      this.updatetabledata(id_pors, 3, 2);
    },
    //撤销已审批通过
    revocation_pass(id_pors) {
      //参数一：记录ID  参数二: 2 修改成待审核状态
      //参数三: 3 重新加载数据   表示：审批通过状态
      this.updatetabledata(id_pors, 2, 3);
    },
    //撤销已审批不通过，资料不全
    revocation_nopass(id_pors) {
      //参数一：记录ID  参数二: 2 修改成待审核状态
      //参数三: 6 重新加载数据  表示：审批不通过，资料不全
      this.updatetabledata(id_pors, 2, 6);
    },
    //
    loadtabledata(status_id, rows, pags) {
      axios
        .get("admin/FkRequest", {
          params: {
            project_id: this.project_id,
            order_status: status_id,
            page: pags,
            list_rows: rows,
            startDate:this.startDate,
            endDate:this.endDate,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.current_page, "******");
          this.currentPage = response.data.current_page; //当前页码  默认第1页
          this.lotalrows = response.data.total; //总行数
          this.tableData.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //更新记录状态
    updatetabledata(idval, status, load_status) {
      let urlstr = "admin/FkRequest/" + idval + "/update_status";
      axios
        .post(
          urlstr,
          { order_status: status, cfo_id: this.user_id },
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("操作成功！", "提示", {
            confirmButtonText: "OK",
          });
          //重新加载数据
          this.loadtabledata(load_status, this.pageSize, this.currentPage);
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("操作失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看pdf清单列表
    pdfview(vals) {
      this.prdclassid = vals;
      this.pdfurlVisible = true;
    },
    //格式化数字
    toThousands(cellValue) {
      return cellValue.sq_amount.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //合计行
    summaryMethod(param) {
      let arr = [];
      let totalsum5 = 0;

      param.data.forEach((element) => {
        totalsum5 += element.sq_amount;
      });

      arr[0] = "合计";

      arr[5] = totalsum5.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });

      return arr;
    },
  },
};
</script>

<style></style>
